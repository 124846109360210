import(/* webpackMode: "eager", webpackExports: ["SecureCookiesProvider"] */ "/vercel/path0/node_modules/.pnpm/next-client-cookies@2.0.0_next@14.2.5_react-dom@18.3.1_react@18.3.1__react@18.3.1__react@18.3.1/node_modules/next-client-cookies/dist/provider.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/.pnpm/next-intl@3.24.0_next@14.2.5_react-dom@18.3.1_react@18.3.1__react@18.3.1__react@18.3.1/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.5_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.js\",\"import\":\"Poppins\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-poppins\",\"weight\":[\"100\",\"200\",\"300\",\"400\",\"500\",\"600\",\"700\",\"800\",\"900\"]}],\"variableName\":\"poppins\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/css/bootstrap.min.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/aos@3.0.0-beta.6/node_modules/aos/dist/aos.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/css/icofont.min.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/css/glightbox.min.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/swiper@11.1.14/node_modules/swiper/swiper.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/swiper@11.1.14/node_modules/swiper/modules/navigation.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/swiper@11.1.14/node_modules/swiper/modules/pagination.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/globals.css");
